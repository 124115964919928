const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://0grxy6azra.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://f7ak5sghw0.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://d71hjr9l26.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://k9ttmpq1n8.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;